<template>
  <ModuleForm>
    <template #form-title>
      {{ $t('notification.addSite') }}
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="existingDetail"
        :record-id="notification.siteUuid"
        :required-permissions="requiredPermissions.deleteButton"
        action-id-param="siteUuid"
        store-name="notificationService"
        list-router-path="notification"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/notification')"
       />
    </template>
    <template #left-column>
      <SiteSelect
        v-if="vlm"
        v-model="notification.siteUuid"
        :options="sites"
        @blur="$v.notification.siteUuid.$touch()"
        :error="$v.notification.siteUuid.$error"
        id="notification_sites"
        class="mb-3"
        :label="$t('notification.siteName')"
        :disabled="disabled"
      />

      <Select
        v-else
        v-model="notification.siteUuid"
        :option-value="siteOptionValue"
        :options="sites"
        @blur="$v.notification.siteUuid.$touch()"
        :error="$v.notification.siteUuid.$error"
        id="notification_sites"
        :label="$t('notification.siteName')"
        :disabled="disabled"
      />

      <Input
        type="number"
        v-model="notification.limit"
        @blur="$v.notification.limit.$touch()"
        :error="$v.notification.limit.$error"
        :step="1"
        :min="0"
        :max="9999"
        id="notification_limit"
        :label="$t('notification.limit')"
        :placeholder="$t('notification.limit_placeholder')"
        :disabled="disabled"
      />
      <OutlinedCheckbox
        v-model="notification.active"
        id="notification_active"
        :label="$t('notification.active')"
        :disabled="disabled"
        no-label-margin
        style="margin-bottom:16px"
      />
    </template>
    <template #right-column>
      <Input
        v-model="notification.appId"
        @blur="$v.notification.appId.$touch()"
        :error="$v.notification.appId.$error"
        id="notification_appId"
        :label="$t('notification.appId')"
        :disabled="disabled"
      />
      <Input
        v-model="notification.apiKey"
        @blur="$v.notification.apiKey.$touch()"
        :error="$v.notification.apiKey.$error"
        id="notification_apiKey"
        :label="$t('notification.apiKey')"
        :disabled="disabled"
      />
      <Input
        v-model="notification.utm"
        @blur="$v.notification.utm.$touch()"
        :error="$v.notification.utm.$error"
        id="notification_utm"
        :label="$t('notification.utm')"
        :disabled="disabled"
      />
    </template>
  </ModuleForm>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Input from '@/components/form/inputs/Input'
import OneSignalModel from '@/model/OneSignalModel'
import NotifyService from '@/services/NotifyService'
import ModuleForm from '@/components/shared/ModuleForm'
import OutlinedCheckbox from '@/components/form/OutlinedCheckbox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import Select from '@/components/form/select/Select'
import NotificationServiceApi from '@/api/notificationService'
import { mapGetters } from 'vuex'
import PermissionService from '@/services/PermissionService'
import SiteSelect from '@/components/form/select/SiteSelect.vue'

export default {
  name: 'NotificationNewView',
  data () {
    return {
      notification: this._.cloneDeep(OneSignalModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_NOTIFICATION_PERMISSIONS
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    notification: {
      siteUuid: {
        required
      },
      limit: {
        required
      },
      appId: {
        required
      },
      apiKey: {
        required
      },
      utm: {
        required
      }
    }
  },
  components: {
    SiteSelect,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    ModuleForm,
    OutlinedCheckbox,
    Select
  },
  computed: {
    ...mapGetters(['vlm']),
    siteOptionValue () {
      return this.vlm ? 'entityUuid' : 'id'
    },
    sites () {
      return this.$store.getters['site/allSorted']()
    },
    existingDetail () {
      return this.$store.getters['notificationService/detail']
    }
  },
  methods: {
    async save () {
      try {
        this.$v.$touch()
        if (this.$v.$invalid) {
          NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
          return
        }
        const url = `api/onesignal/settings/${this.notification.siteUuid}`
        await NotificationServiceApi().put(url, this.notification)
        NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
        this.goToEdit()
      } catch (error) {
        console.error(error)
        NotifyService.setErrorMessage(error)
      }
    },
    goToEdit () {
      this.$router.push('/notification/' + this.notification.siteUuid + '/edit')
    }
  }
}
</script>
